<template>
  <div class="common-aside">
    <el-aside width="auto">
      <el-menu router :default-active="activePath" class="el-menu-vertical" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
        <template :index="item.id" v-for="item in sliderData">
          <el-menu-item :index="item.frontend_api" :key="item.id" v-if="item.children.length === 0">
            <i :class="item.icon"></i>
            <span>{{ item.title}}</span>
          </el-menu-item>
          <el-submenu :key="item.id" v-else :index="item.id.toString()">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.title}}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item :index="subItem.frontend_api" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState(subItem.frontend_api)">
              <!-- 导航开启路由模式：
                将index值作为导航路由 -->
              <!-- 二级菜单的模板区域 -->
              <template slot="title">
                <i :class="subItem.icon"></i>
                <span>{{ subItem.title}}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </el-aside>
    <div class="toggle-menu">
      <el-switch v-model="isCollapse" active-color="#13ce66" :active-value="false" :inactive-value="true" inactive-color="#ff4949">
      </el-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonAside',
  props: {
    sliderData: Array
  },
  data () {
    return {
      isCollapse: false,
      // 被激活导航地址
      activePath: ''
    }
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    // 保存连接的激活地址
    saveNavState (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
    }
  }
}
</script>

<style scoped>
.common-aside{
  height: 100%;
  position: relative;
  background-color: #d3dce6;
  border-radius: 10px 0 0 10px;
}
.el-aside {
  color: #333;
  text-align: left;
  line-height: 200px;
  max-width: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px 0 0 0;
  height: calc(100% - 60px);
}
.el-menu{
  border-right: 0;
  background-color: #d3dce6;
}
.el-menu-vertical:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.toggle-menu{
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.left-el-menu:not(.el-menu--collapse){
  overflow-y: auto;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.el-aside::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  background-color: #d3dce6;
}

/*定义滚动条轨道 内阴影+圆角*/
.el-aside::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
.el-aside::-webkit-scrollbar-thumb{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  background-color: rgb(19, 206, 102);
}
</style>
