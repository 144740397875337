<template>
  <div class="common-header">
    <el-header>
      <div class="left">
        <div class="item" @click="fullscreen">
          <i class="el-icon-full-screen"></i>
        </div>
      </div>
      <div class="right">
        <div class="menu-list">
          <div class="menu-item" @click="showMsgList = !showMsgList">
            <el-badge :value="0">
              <i class="el-icon-bell"></i>
            </el-badge>
          </div>
          <div class="menu-item">
            <i class="el-icon-collection"></i>
          </div>
          <div class="user-area">
            <el-dropdown>
              <span class="el-dropdown-link">
                <el-avatar :src="this.$store.state.userInfo.avatar"></el-avatar>
                <span style="margin-left: 10px">{{ this.$store.state.userInfo.nick }}</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>个人资料</el-dropdown-item>
                <el-dropdown-item @click.native="clear">清除缓存</el-dropdown-item>
                <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 消息列表 -->
        <div class="message-panel" ref="msgPanel" v-show="showMsgList">
          <div class="title">消息列表</div>
          <div class="message-list">
            <div class="message-item">
              <div class="icon">
                <el-icon class="el-icon-star-off"></el-icon>
              </div>
              <div class="content">
                <div class="msg-title">系统提示消息</div>
                <div class="msg-content">*** 于2022年03月16日 15：31注册了账号，请前去审核该账号</div>
              </div>
            </div>
            <div class="message-item">
              <div class="icon">
                <el-icon class="el-icon-star-off"></el-icon>
              </div>
              <div class="content">
                <div class="msg-title">系统提示消息</div>
                <div class="msg-content">*** 于2022年03月16日 15：31注册了账号，请前去审核该账号</div>
              </div>
            </div>
            <div class="message-item">
              <div class="icon">
                <el-icon class="el-icon-star-off"></el-icon>
              </div>
              <div class="content">
                <div class="msg-title">系统提示消息</div>
                <div class="msg-content">*** 于2022年03月16日 15：31注册了账号，请前去审核该账号</div>
              </div>
            </div>
            <div class="message-item">
              <div class="icon">
                <el-icon class="el-icon-star-off"></el-icon>
              </div>
              <div class="content">
                <div class="msg-title">系统提示消息</div>
                <div class="msg-content">*** 于2022年03月16日 15：31注册了账号，请前去审核该账号</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-header>
  </div>
</template>

<script>
export default {
  name: 'CommonHeader',
  data () {
    return {
      showMsgList: false
    }
  },
  created () {
  },
  methods: {
    fullscreen () {
    },
    // 退出登录
    logout () {
      this.$store.commit('clearUser')
      // 清除token
      this.$store.commit('clearToken')
      // 清除session中的token
      window.sessionStorage.removeItem('token')
      this.$message.success('退出登录成功')
      this.$router.push('/login')
    },
    // 清楚缓存
    async clear () {
      const { data: res } = await this.$http.post('/clear-cache')
      if (res.status === 200) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  mounted () {
    document.querySelector('.message-panel').addEventListener('mouseleave', () => {
      this.showMsgList = false
    })
  }
}
</script>

<style scoped>
.el-header{
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
  border-radius: 0 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
}
.left .item{
  cursor: pointer;
  width: 40px;
}
.right{
  position: relative;
}
.right .menu-list{
  display: flex;
  align-items: center;
  justify-content: center;
}
.right .menu-list .menu-item{
  width: 30px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right .menu-list .menu-item i{
  font-size: 16px;
}
.right .menu-list .menu-item .el-badge{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right .menu-list .user-area{
  height: 60px;
  margin-left: 10px;
}
.el-dropdown-link{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.el-dropdown-link .el-avatar{
  width: 30px;
  height: 30px;
}
.message-panel{
  position: absolute;
  background-color: #FFFFFF;
  top: 64px;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  line-height: 1.2;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.message-panel .title{
  padding-bottom: 10px;
}
.message-panel .message-list{
  max-height: 300px;
  overflow-y: auto;
}
/*滚动条整体样式*/
.message-panel .message-list::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}
/*滚动条里面小方块*/
.message-panel .message-list::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2);
  background: #535353;
}
/*滚动条里面轨道*/
.message-panel .message-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2);
  border-radius: 6px;
  background: #EDEDED;
}
.message-panel .message-list .message-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 6px;
  border: 1px solid rgba(0,0,0,0.08);
  border-radius: 6px
}
.message-panel .message-list .message-item .icon{
  width: 30px;
}
.message-panel .message-list .message-item .content{
  text-align: left;
  flex: 1;
}
.message-panel .message-list .message-item .content .msg-title{
  font-weight: bold;
  color: #303133;
  font-size: 14px;
}
.message-panel .message-list .message-item .content .msg-content{
  font-size: 14px;
  color: #909399;
  margin-top: 4px;
}
.message-panel .message-list .message-item .content .link{}
</style>
