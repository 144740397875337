<template>
  <div class="admin-page">
    <div class="dashboard-container">
      <CommonAside :sliderData="menus"></CommonAside>
      <el-container>
        <CommonHeader></CommonHeader>
        <el-main>
          <router-view></router-view>
        </el-main>
        <CommonFooter :copyright="copyright"></CommonFooter>
      </el-container>
    </div>
  </div>
</template>

<script>
import CommonHeader from '../components/common/CommonHeader'
import CommonAside from '../components/common/CommonAside'
import CommonFooter from '../components/common/CommonFooter'
export default {
  name: 'Admin',
  components: {
    CommonHeader,
    CommonAside,
    CommonFooter
  },
  data () {
    return {
      menus: null,
      copyright: ''
    }
  },
  created () {
    this.getBaseData()
  },
  methods: {
    // 获取菜单和版权信息
    async getBaseData () {
      const { data: res } = await this.$http.get('common-data')
      if (res.status === 200) {
        this.menus = res.data.menu
        this.copyright = res.data.copyright
      }
    }
  }
}
</script>

<style scoped>
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
*::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  background-color: #d3dce6;
}

/*定义滚动条轨道 内阴影+圆角*/
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
*::-webkit-scrollbar-thumb{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  background-color: rgb(19, 206, 102);
}
.admin-page{
  width: 100%;
  height: 100vh;
  padding: 40px;
}
.dashboard-container{
  width: 100%;
  height: 100%;
  display: flex;
}
.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

.el-container {
  display: flex;
  flex-direction: column;
}
/deep/.el-breadcrumb {
  font-size: 14px;
  line-height: 1;
  padding: 20px 0;
}
</style>
