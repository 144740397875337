<template>
  <div class="common-footer">
    <el-footer>{{copyright}}</el-footer>
  </div>
</template>

<script>
export default {
  name: 'CommonFooter',
  props: {
    copyright: String
  },
  data () {
    return {
      timer: null,
      outTry: 1
    }
  },
  created () {
    // 初始化方法
    // this.localSocket()
  },
  methods: {
    localSocket () {
      const that = this
      if ('WebSocket' in window) {
        that.ws = new WebSocket('ws://' + '127.0.0.1:8282')
        that.global.setWs(that.ws)
        that.ws.onerror = () => {
          that.ws.close()
          // 发起重连
        }
        that.ws.onopen = () => {
          console.log('websocket连接成功')
          this.$notify({
            title: '消息服务链接成功',
            message: 'websocket连接成功',
            type: 'success'
          })
        }
        that.ws.onclose = () => {
          this.$notify({
            title: '消息服务连接已断开！',
            message: 'websocket连接失败，正在尝试重新第' + that.outTry + '连接',
            type: 'error'
          })
          that.outTry++
          // 断线重新连接
          if (that.outTry <= 5) {
            that.timer = setTimeout(() => {
              that.localSocket()
            }, 1500)
          } else {
            clearTimeout(that.timer)
            this.outTry = 1
          }
        }
      } else {
        console.log('您的浏览器不支持 WebSocket! 请更换其他浏览器后重试！')
        // 浏览器不支持 WebSocket
        this.$notify.error({
          title: '错误',
          message: '您的浏览器不支持 WebSocket! 请更换其他浏览器后重试！',
          duration: 0
        })
      }
    }

  }
}
</script>

<style scoped>
.el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
  border-radius: 0 0 10px 0;
}
</style>
